<template>
    <div>
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="filter">
            <div class="butt" @click="ruleVisible = true">创建+</div>
            <el-input prefix-icon="el-icon-search" placeholder="请输入搜索内容" v-model="searchValue" clearable></el-input>
        </div>
        <div class="list">
            <div class="item" @click="$router.push({ path: '/communityDetail', query: { id: item.id } })" v-for="(item, index) in list" :key="index">
                <div>
                    <img :src="item.picUrl" alt="" />
                    <span class="title">{{ item.name }}</span>
                </div>
            </div>
        </div>
        <el-pagination @size-change="getData" @current-change="getData" :current-page.sync="page" :page-sizes="[12, 24, 36, 48]" :page-size.sync="limit" layout="prev, pager, next,jumper" class="pagination" :total="total" background></el-pagination>
        <!--创建社群-->
        <el-dialog title="社群" :visible.sync="ruleVisible" width="40%" class="dialog" append-to-body>
            <div class="item">
                <div>
                    <img src="@/assets/images/Frame652.png" alt="" />
                    <span class="title">名称：</span>
                </div>
                <el-input v-model="name" placeholder="请输入名称"></el-input>
            </div>
            <div class="item uploads">
                <div>
                    <img src="@/assets/images/Frame652.png" alt="" />
                    <span class="title">封面：</span>
                </div>
                <el-upload action="#" list-type="picture-card" :http-request="upDate" :on-preview="handlePictureCardPreview" :on-remove="handleRemove" :file-list="pictures" :class="{ hide: hideUploadBtn }" :on-change="handleEditChange" :limit="1">
                    <i class="el-icon-plus"></i>
                </el-upload>
            </div>
            <div class="item">
                <div>
                    <img src="@/assets/images/Frame652.png" alt="" />
                    <span class="title">简介：</span>
                </div>
                <el-input v-model="briefIntroduction" :rows="5" type="textarea" placeholder="请输入简介"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="ruleVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirm">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisible" append-to-body class="dialog1">
            <img width="50%" :src="dialogImageUrl" alt="" />
        </el-dialog>
    </div>
</template>

<script>
import uploadFile from '@/utils/oss/ossClient';

export default {
    name: 'about',
    data() {
        return {
            limit: 12,
            page: 1,
            total: 0,
            list: [],
            searchValue: '',
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
                {
                    title: '社群',
                },
            ],
            name: '', // 社群名称
            briefIntroduction: '', // 简介
            fengmian: '',
            ruleVisible: false,
            dialogImageUrl: '',
            dialogVisible: false,
            images: [],
            picUrl: '',
            pictures: [],
            hideUploadBtn: false,
        };
    },
    watch: {
        searchValue() {
            this.pageNum = 1;
            this.getData();
        },
    },
    created() {
        this.getCommunityList();
    },
    methods: {
        // 最多上传1张图，超过时隐藏上传按钮
        handleEditChange(file, fileList) {
            this.hideUploadBtn = fileList.length >= 1;
        },
        upDate(e) {
            uploadFile(e).then((res) => {
                this.picUrl = res[0];
                this.pictures.push({ uid: e.file.uid, url: res[0] });
            });
        },
        getData() {
            console.log(this.page, this.limit);
            this.getCommunityList();
        },
        handleRemove(file, fileList) {
            this.picUrl = '';
            this.pictures = fileList;
            this.hideUploadBtn = fileList.length >= 1;
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        //  获取社群列表
        getCommunityList() {
            let data = {
                pageNum: this.page,
                pageSize: this.limit,
            };
            if (this.searchValue) {
                data.name = this.searchValue;
            }
            this.$hao.getCommunityList(data).then((res) => {
                console.log(res);
                this.list = res.rows;
                this.total = res.total;
            });
        },
        confirm() {
            if (this.name == '') {
                this.$message.error('请输入名称');
                return;
            }
            if (this.briefIntroduction == '') {
                this.$message.error('请输入简介');
                return;
            }
            if (this.picUrl == '') {
                this.$message.error('请上传图片');
                return;
            }
            let data = {
                name: this.name,
                briefIntroduction: this.briefIntroduction,
                picUrl: this.picUrl,
            };
            this.$hao.addCommunity(data).then((res) => {
                console.log(res);
                this.$message.success('创建成功');
                this.ruleVisible = false;
                // this.getCommunityList();
            });
        },
    },
};
</script>

<style scoped lang="less">
.filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;

    ::v-deep .el-input {
        width: 240px;
        height: 30px;
        border-radius: 50px;
    }

    ::v-deep .el-input__inner {
        border-radius: 20px;
        height: 30px;
    }

    ::v-deep .el-input__icon {
        line-height: 30px;
    }

    .butt {
        padding: 4px 12px;
        background: #f33f3e;
        border-radius: 2px;
        color: #ffffff;
        cursor: pointer;
    }
}

.list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 30px;

    .item {
        margin-bottom: 56px;
        cursor: pointer;

        div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        img {
            width: 100%;
            height: 175px;
            border-radius: 2px;
            filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16));
        }

        .title {
            font-weight: 700;
            font-size: 18px;
            line-height: 26px;
            text-align: center;
            color: #333333;
            flex: none;
            order: 1;
            flex-grow: 0;
            margin-top: 13px;
            width: 90%;
            .ellipsisLine(1);
        }
    }
}
.dialog {
    padding-top: 8px;
    .item {
        display: flex;
        flex-direction: column;
        ::v-deep .el-input__inner {
            border-radius: 4px;
        }

        div {
            margin-bottom: 12px;
            .title {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #333333;
                flex: none;
                order: 0;
                flex-grow: 0;
            }

            img {
                width: 7px;
                height: 17px;
            }
        }

        .jifen {
            font-size: 14px;
            line-height: 20px;
            text-align: right;
            color: #fd8f30;
        }
    }

    .uploads {
        .hide {
            ::v-deep .el-upload--picture-card {
                display: none !important;
            }
        }
        ::v-deep .el-upload--picture-card {
            margin: 0 10px 10px 0;
            width: 76px;
            height: 76px;
            line-height: 86px;
        }
        ::v-deep .el-upload-list__item {
            width: 76px;
            height: 76px;
        }
        .avatar-uploader .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }
        .avatar-uploader .el-upload:hover {
            border-color: #409eff;
        }
        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 178px;
            height: 178px;
            line-height: 178px;
        }
        .avatar {
            width: 178px;
            height: 178px;
            display: block;
        }
    }
}
.dialog1 {
    text-align: center;
}

::v-deep .el-dialog__body {
    padding: 24px 24px 12px 24px;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
}
</style>
